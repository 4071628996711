/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
       $('.bxslider').bxSlider({
        nextSelector: '#slider-next',
        prevSelector: '#slider-prev',
        nextText: '&rsaquo;',
        prevText: '&lsaquo;',
        mode: 'fade',
        captions: false,
        pager: false
        }); // JavaScript to be fired on all pages

          $('.navbar-nav li a').removeAttr('title');

       $( ".services-dropdown" ).click(function() {
          $( ".secondary-menu-container" ).toggle();
        });
       $( ".lang-item a" ).attr('title','');

       $('img.svg').each(function(){
           var $img = $(this);
           var imgID = $img.attr('id');
           var imgClass = $img.attr('class');
           var imgURL = $img.attr('src');
           $.get(imgURL, function(data) {
               var $svg = $(data).find('svg');
               if(typeof imgID !== 'undefined') {
                   $svg = $svg.attr('id', imgID);
               }
               if(typeof imgClass !== 'undefined') {
                   $svg = $svg.attr('class', imgClass+' replaced-svg');
               }
               $svg = $svg.removeAttr('xmlns:a');

               $img.replaceWith($svg);
           }, 'xml');
       });

       var email = $( "#service-email" ).text();
       var mailto = "mailto:" + email;
       $( ".mailto" ).attr( "href", mailto);
       $(document).on("scroll",function(){
          if($(document).scrollTop()>100){
              $("header").removeClass("big").addClass("small");
          } else{
              $("header").removeClass("small").addClass("big");
          }
      });
      $('.first-child').on('click', function(e){
    e.stopPropagation();
    $(this).find('.second-child').toggle();
    $(this).find(".more").toggleClass("red");
});
      $('.second-child').on('click', function(e){
    e.stopPropagation();
    $(this).find('.third-child').toggle();
    $(this).find(".more1").toggleClass("red");
});
      $('.third-child').on('click', function(e){
    e.stopPropagation();
    $(this).find('.fourth-child').toggle();
    $(this).find(".more2").toggleClass("red");
});
/*$('.no-more').on('click', function(e){
    e.stopPropagation();
    var id_category = $(this).attr('data');
      jQuery.post(
          ajaxurl,
          {
              'action': 'my_ajax_link',
              'data':   id_category
          },
          success:function(data) {
            console.log(data);

          }
      );
  });*/
  $('.no-more').on('click', function(e){
    e.stopPropagation();
    var id_category = $(this).attr('data');
      jQuery.ajax({
        type: 'POST',
        url: ajaxurl,
        data: {
          'action': 'my_ajax_link',
          'data':   id_category
        },
        success:function(data) {

          $("#products").removeClass("no").addClass("yes");
          // idedam produktus i product tab

          $( ".no-more" ).click(function() {
          $( "#products" ).empty();
          });
          $.each( data, function( count, post ) {
            if( count % 2 === 0) {

            var $div = $("<div class='row service-product-second'><div class='row inside'><div class='col-md-6 item-service-about'><h2></h2><p>" +
                        data[count][2] +
                        "</p></div><div class='col-md-6 item-service-image'><p class='larrow'></p><img class='img-responsive link'><img class='img-responsive link' src=" +
                        data[count][0] +
                        "><h1>" +
                        data[count][1] +
                        "</h1></div></div></div>");
          } else {
            var $div2 = $("<div class='row service-product-first'><div class='row inside'><div class='col-md-6 item-service-image'><p class='rarrow'></p><h2></h2><img class='img-responsive link' src=" +
                        data[count][0] +
                        "><h1>" +
                        data[count][1] +
                        "</h1></div><div class='col-md-6 item-service-about'><p>" +
                        data[count][2] +
                        "</p></div></div></div>");
          };
            $("#products").append($div);
            $("#products").append($div2);

          });


        }
      }
      );
  });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
